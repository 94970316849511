<template>
  <b-container class="contacts">
    <b-row>
      <b-col class="contacts__header">
        {{ $t('Contacts_title') }}
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" lg="8">

        <div class="q-card contacts-card">
<!--          <a href="https://api.whatsapp.com/send/?phone=77471111818&text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5%21+%D0%AF+%D1%85%D0%BE%D1%87%D1%83+%D0%B7%D0%B0%D0%BA%D0%B0%D0%B7%D0%B0%D1%82%D1%8C+%D1%82%D0%BE%D1%80%D1%82&type=phone_number&app_absent=0"-->
<!--             target="_blank"-->
<!--             class="q-btn q-btn-green">-->
<!--            WhatsApp-->
<!--          </a>-->
          <router-link to="/whatsapp" class="main-nav__link q-btn q-btn-green">WhatsApp</router-link>

          <br />
          <br />
          <br />
          <a href="tel:77001101059" class="contacts-card__phone">+ 7 (700) 110 10 59</a>

          <div class="contacts-card__feedback">
            <span>{{ $t('Contacts_question_form') }}</span>
            <a href="mailto:feedback@qulpynai.kz">feedback@qulpynai.kz</a>
          </div>
          <div class="contacts-card__head">
            <div>{{ $t('Contacts_shops') }}</div>
            <a href="https://2gis.kz/almaty/branches/70000001026664738?m=76.913684%2C43.230445%2F12.74&utm_referrer=http%3a%2f%2f172.31.127.198%3a8080%2f&fa821dba_ipp_key=v1668785638695%2Fv33947245bb5ad87a72e273%2FdC6Z0HRjMO7hvSwh8bzRpg%3d%3d&fa821dba_ipp_uid=1662381174603%2fkadF3nDZuNf8Yi5H%2fPm%2f3EXJ%2fOIpbmYZxRiQ%2bXA%3d%3d"
               target="_blank">
              {{ $t('Contacts_area_deliveries') }}
            </a>

          </div>
          <div class="contacts-card__items">
            <div
              v-for="(item, i) in shops"
              :key="`contact-shop__${i}`"
              class="contacts-card__item"
              v-if="item.visible"
            >
              <div class="contacts-card__item-address">
                <a :href="item.link" target="_blank">{{ $t(item.name) }}</a>
              </div>

              <div class="contacts-card__item-phone">
                {{ item.phone }}
              </div>

              <div class="contacts-card__item-hours">
                {{ item.start_work }} - {{ item.end_work}}
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="12" lg="4">
        <feedback-form type="contacts" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import FeedbackForm from "@/components/Forms/FeedbackForm.vue";
import {mapState} from "vuex";

export default {
    name: "Contacts",
    components: {
        FeedbackForm,
    },

    metaInfo: {
      title: 'Контакты | Доставка кондитерских изделий в Алмате',
      meta: [
        { vmid: 'description', property: 'description', content: 'Qulpynai Контакты' },
        { vmid: 'og:title', property: 'og:title', content: 'Contacts Qulpynai' },
        { vmid: 'og:description', property: 'og:description', content: 'Qulpynai Контакты' },
      ],
    },
  computed: {
    ...mapState({
      shops: state => state.filial.filials,
    })
  },
    data() {
        return {
            // shops: [
            //   {
            //     id: 1,
            //     name: 'Contacts_shaimerden',
            //     phone: '+7 747 814 99 44',
            //     hours: 'hours_shaimerden',
            //     link: 'https://2gis.kz/almaty/branches/70000001026664738/firm/70000001026664740/76.85523%2C43.198495?m=76.865379%2C43.197275%2F15.5',
            //   },
            //   {
            //     id: 2,
            //     name: 'Contacts_seifullin',
            //     phone: 'phone_seifullin',
            //     hours: 'hours_seifullin',
            //     link: 'https://2gis.kz/almaty/branches/70000001026664738/firm/70000001031984652/76.932325%2C43.259953?m=76.933525%2C43.258138%2F16.68',
            //   },
            //   {
            //     id: 3,
            //     name: 'Contacts_aksay',
            //     phone: 'phone_aksay',
            //     hours: 'hours_aksay',
            //     link: 'https://2gis.kz/almaty/branches/70000001026664738/firm/70000001037239323/76.832594%2C43.231122?m=76.836282%2C43.230313%2F15.9',
            //   },
            //   {
            //     id: 4,
            //     name: 'Contacts_3mkr',
            //     phone: 'phone_3mkr',
            //     hours: 'hours_3mkr',
            //     link: 'https://2gis.kz/almaty/branches/70000001026664738/firm/70000001045806075/76.849917%2C43.22225?m=76.854625%2C43.221527%2F15.55',
            //   },
            //   {
            //     id: 5,
            //     name: 'Contacts_tole_bi',
            //     phone: 'phone_tole_bi',
            //     hours: 'hours_tole_bi',
            //     link: 'https://2gis.kz/almaty/branches/70000001026664738/firm/70000001048534082/76.933961%2C43.254552?m=76.93427%2C43.251335%2F15.54',
            //   },
            //   {
            //     id: 6,
            //     name: 'Contacts_syrgabekova',
            //     phone: 'phone_syrgabekova',
            //     hours: 'hours_syrgabekova',
            //     link: 'https://2gis.kz/almaty/branches/70000001026664738/firm/70000001053328189/76.913401%2C43.200818?m=76.915789%2C43.20036%2F16.04',
            //   },
            //   {
            //     id: 7,
            //     name: 'Contacts_4you',
            //     phone: 'phone_4you',
            //     hours: 'hours_syrgabekova',
            //     link: 'https://2gis.kz/almaty/branches/70000001026664738/firm/70000001061697811/76.894128%2C43.208082?m=76.89959%2C43.207468%2F16.42',
            //   },
            //   {
            //     id: 8,
            //     name: 'Contacts_eginsu',
            //     phone: 'phone_eginsu',
            //     hours: 'hours_eginsu',
            //     link: 'https://2gis.kz/almaty/branches/70000001026664738/firm/70000001056464684/76.801799%2C43.177947?m=76.808244%2C43.176774%2F16.37',
            //   },
            //   {
            //     id: 9,
            //     name: 'Contacts_sputnik',
            //     phone: 'phone_sputnik',
            //     hours: 'hours_sputnik',
            //     link: 'https://2gis.kz/almaty/branches/70000001026664738/firm/70000001057867753/76.842288%2C43.212077?m=76.847411%2C43.210109%2F15.73',
            //   },
            //   {
            //     id: 10,
            //     name: 'Contacts_duman',
            //     phone: 'phone_duman',
            //     hours: 'hours_duman',
            //     link: 'https://2gis.kz/almaty/branches/70000001026664738/firm/70000001064916659/76.997247%2C43.286933?m=76.997805%2C43.285652%2F15.93',
            //   },
            //   {
            //     id: 11,
            //     name: 'Contacts_AlmaCity',
            //     phone: 'phone_almacity',
            //     hours: 'hours_almacity',
            //     link: 'https://2gis.kz/almaty/branches/70000001026664738/firm/70000001066038123/76.787139%2C43.223322?m=76.789025%2C43.223101%2F15.93',
            //   },
            // ],
        }
    },
  created() {
    let filials
    this.$store.dispatch('filial/getFilials').then(res => {
      filials = res
    });
  },
}
</script>
