<template>
  <b-container class="order-page">
    <b-row>
      <b-col cols="12" lg="8">
        <div v-if="hasError" class="q-card order-card mt-2">
          <div class="order-card__head-date">
            <div class="order-card__head-hour">
              {{ $t("Order_tech_error") }}
            </div>
          </div>
          <div class="order-card__issue">
            {{ $t("Order_popup_error") }}
          </div>
          <div class="order-card__footer q-desktop-only">
            <button class="q-btn q-btn-red">
              {{ $t("Order_btn2cabinet") }}
            </button>
            <button class="q-btn">{{ $t("Order_btn2back") }}</button>
          </div>
        </div>

        <order-card v-else></order-card>
      </b-col>
      <b-col cols="12" lg="4">
        <div class="q-card order-page__call">
          <div>{{ $t("Order_have_question") }}</div>
          <a href="tel:77001101059">+7 700 110 10 59</a>
          <a href="https://api.whatsapp.com/send/?phone=77471111818&text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5%21+%D0%AF+%D1%85%D0%BE%D1%87%D1%83+%D0%B7%D0%B0%D0%BA%D0%B0%D0%B7%D0%B0%D1%82%D1%8C+%D1%82%D0%BE%D1%80%D1%82&type=phone_number&app_absent=0" target="_blank" class="q-btn q-btn-green">+7 (747) 111 18 18</a>

        </div>
        <div class="q-card order-page__status-wrap">
          <div
            class="order-page__status"
            v-if="!hasError && order.status_id !== 12"
          >
            <div class="order-page__status-title">
              {{ $t("Order_status") }}
            </div>
            <div
              class="order-page__status-item"
              :class="{
                'order-page__status-item-current': order.status_id === 2,
                'order-page__status-item-green':
                  order.status_id > 2 && !canceled,
              }"
            >
              <div
                class="order-page__status-icon order-page__status-icon-step1"
              />
              <div class="order-page__status-name">
                {{ $t("Order_accept") }}
                <div v-if="order.payment?.type != 'cash' && !order.payed_at">
                  {{ $t("Order_OrderTimeline_Order_Wait_Pay") }}
                </div>
                <div
                  v-else-if="order.payment?.type != 'cash' && order.payed_at"
                >
                  {{ $t("Order_OrderTimeline_Order_Payed") }}
                </div>
              </div>
            </div>
            <div
              class="order-page__status-item"
              :class="{
                'order-page__status-item-current': order.status_id === 4,
                'order-page__status-item-green': order.status_id > 4,
                'order-page__status-item-red': canceled,
              }"
            >
              <div
                class="order-page__status-icon order-page__status-icon-step2"
              ></div>
              <div class="order-page__status-name">
                {{ canceled ? $t("Order_cancelled") : $t("Order_waitcooking") }}
              </div>
            </div>
            <template v-if="!canceled">
              <div
                class="order-page__status-item"
                :class="{
                  'order-page__status-item-current': order.status_id === 5,
                  'order-page__status-item-green': order.status_id > 5,
                }"
              >
                <div
                  class="order-page__status-icon order-page__status-icon-step3"
                ></div>
                <div class="order-page__status-name">
                  {{ $t("Order_ready") }}
                </div>
              </div>
              <div
                class="order-page__status-item"
                :class="{
                  'order-page__status-item-current': order.status_id === 7,
                  'order-page__status-item-green': order.status_id > 7,
                }"
                v-if="order.is_delivery"
              >
                <div
                  class="order-page__status-icon order-page__status-icon-step4"
                ></div>
                <div class="order-page__status-name">
                  {{ $t("Order_delivery") }}
                </div>
              </div>
              <div
                class="order-page__status-item"
                :class="{
                  'order-page__status-item-current': order.status_id === 8,
                  'order-page__status-item-green': order.status_id > 8,
                }"
              >
                <div
                  class="order-page__status-icon order-page__status-icon-step5"
                ></div>
                <div class="order-page__status-name">
                  {{
                    order.is_delivery
                      ? $t("Order_delivered")
                      : $t("Order_closed")
                  }}
                </div>
              </div>
            </template>
          </div>
          <div class="order-card__footer q-mobile-only mt-4">
            <router-link to="/cabinet" class="q-btn q-btn-red">{{
              $t("Order_cabinet_02")
            }}</router-link>
            <router-link to="/" class="q-btn">{{
              $t("Order_back2menu")
            }}</router-link>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row class="justify-content-center mt-5" v-if="false">
      <b-col cols="12" lg="8">
        <div class="q-card order-success">
          <div class="order-success__title">{{ $t("Order_thankyou") }}</div>
          <div class="order-success__text">{{ $t("Order_payed") }}</div>
          <button class="q-btn order-success__print-check">
            {{ $t("Order_check") }}
          </button>
          <button class="q-btn q-btn-teal">{{ $t("Order_statusses") }}</button>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import OrderCard from "@/components/Order/OrderCard";
import OrderPreloader from "@/components/Order/OrderPreloader";
import { mapState } from "vuex";

export default {
  name: "Order",
  components: {
    OrderCard,
    OrderPreloader,
  },
  metaInfo: {
    title: "Подтверждение заказа | Доставка кондитерских изделий в Алмате",
    meta: [
      {
        vmid: "description",
        property: "description",
        content: "Qulpynai кабинет",
      },
      { vmid: "og:title", property: "og:title", content: "Cabinet Qulpynai" },
      {
        vmid: "og:description",
        property: "og:description",
        content: "Qulpynai кабинет",
      },
    ],
  },
  computed: {
    ...mapState({
      hasError: (state) => state.order.hasError,
      order: (state) => state.order.order,
    }),
    fullPagePreloader() {
      return this.order.creation_status === "InProgress";
    },
    canceled() {
      return this.order.status_id === 11;
    },
  },
  // watch: {
  //   fullPagePreloader(val){
  //     if(val === true) {
  //       document.body.className = "overflow-h";
  //     } else {
  //       document.body.className = "";
  //     }
  //   }
  // },
  created() {
    this.$store.commit("order/SET_ORDER", {});
    this.$store.dispatch("order/getOrder", this.$route.params.orderId);
  },
};
</script>

<style scoped></style>
